@tailwind base;
@tailwind components;
@tailwind utilities;

/* SWIPER STYLES */
@import "swiper/css";
@import "swiper/css/navigation";
@import "swiper/css/pagination";
@import "react-toastify/dist/ReactToastify.css";
@import "react-phone-number-input/style.css";

/* FONTS */
@font-face {
  font-family: "ProximaNova-Thin";
  src: url("../assets/fonts/ProximaNova-Thin.woff");
}

@font-face {
  font-family: "ProximaNova-Light";
  src: url("../assets/fonts/ProximaNova-Light.woff");
}

@font-face {
  font-family: "ProximaNova-Regular";
  src: url("../assets/fonts/ProximaNova-Regular.woff");
}

@font-face {
  font-family: "ProximaNova-Semibold";
  src: url("../assets/fonts/ProximaNova-Semibold.woff");
}

@font-face {
  font-family: "ProximaNova-Bold";
  src: url("../assets/fonts/ProximaNova-Bold.woff");
}

@font-face {
  font-family: "ProximaNova-Extrabold";
  src: url("../assets/fonts/ProximaNova-Extrabld.woff");
}

* {
  scroll-behavior: smooth;
}

.container {
  max-width: 150vh;
}

.testimonial-gradient {
  background: linear-gradient(0, #fef5f0, transparent);
}

body::-webkit-scrollbar {
  display: none;
}

.banner {
  position: relative;
  background: url("../assets/images/banner.jpg");
  background-position: center;
  background-repeat: no-repeat;
}

.main-header {
  box-shadow: 0px 3px 5px rgba(57, 63, 72, 0.2);
}

.main-header.slide-in {
  animation: slideIn 0.5s ease-in;
}

@keyframes slideIn {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@media (max-width: 575px) {
  .container {
    padding: 0vh 1.2vh;
  }
}

.PhoneInputCountry {
  padding: 0vh 1vh;
}

.PhoneInputInput {
  font-size: 1.5vh;
  font-family: "ProximaNova-Semibold", sans-serif;
  padding: 0.8vh 2.1vh;
}

.PhoneInputInput:focus {
  outline: none;
}

.Toastify__toast-body {
  font-family: "ProximaNova-Semibold", sans-serif;
}
